import { useState } from "react";
import {Row, Col, Drawer, Dropdown} from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";
import IntroContent from "../../content/IntroContent.json";
import ContentBlock from "../ContentBlock";
import i18n from "i18next";

const Header = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    return (
      <>
        <CustomNavLinkSmall onClick={() => scrollTo("about")}>
          <Span>{t("About")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("mission")}>
          <Span>{t("Mission")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("product")}>
          <Span>{t("Product")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => scrollTo("contact")}
        >
          <Span>
            <Button>{t("Contact")}</Button>
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row style={{justifyContent: 'start', alignItems: 'center', gap: '10px'}}>
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="logoo.svg" width="151px" height="92px" />
          </LogoContainer>
          <LanguageSwitchContainer>
            <LanguageSwitch onClick={() => handleChange("en")}>
              <SvgIcon
                  src="united-states.svg"
                  aria-label="homepage"
                  width="30px"
                  height="30px"
              />
            </LanguageSwitch>
            <LanguageSwitch onClick={() => handleChange("ro")}>
              <SvgIcon
                  src="romania.svg"
                  aria-label="homepage"
                  width="30px"
                  height="30px"
              />
            </LanguageSwitch>
          </LanguageSwitchContainer>
        </Row>
      
        <ContentBlock
            type="top"
            title={t("BannerTitle")}
            content={"BannerContent"}
            button={IntroContent.button}
            icon="developer.svg"
            id="intro"
        />
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
