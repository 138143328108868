import {Row, Col} from "antd";
import {withTranslation} from "react-i18next";
import {ContentBlockProps} from "../types";
import {Fade} from "react-awesome-reveal";
import {
    RightBlockContainer,
    ContentWrapper,

} from "./styles";
import Container from "../../../common/Container";

const RightBlock = ({t}: ContentBlockProps) => {

    return (
        <RightBlockContainer>
            <Container >
                    <Row style={{display: 'flex', justifyContent: 'end', marginTop: '150px'}}>
                        <ContentWrapper >
                            <h6 style={{
                                fontSize: '30px',
                                textAlign: 'center',
                                color: 'black',
                                fontWeight: 'bold',
                                marginBottom: '50px'
                            }}>{t("SafetyTemperature")}</h6>
                            <h6 style={{
                                fontSize: '30px',
                                textAlign: 'center',
                                color: 'black',
                                fontWeight: 'bold',
                            }}>{t("SetUpTemperatureFromYourPhone")}</h6>
                        </ContentWrapper>
                    </Row>
            </Container>
        </RightBlockContainer>
    );
};

export default withTranslation()(RightBlock);
