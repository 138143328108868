import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import {
  FooterSection,
  Title,
  NavLink,
  LogoContainer,
  Para,
  Language,
} from "./styles";

const Footer = ({ t }: any) => {
  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="center" style={{ marginBottom: "50px" }}>
            <Col lg={12} md={10} sm={12} xs={12}>
              <Language
                style={{
                  justifyContent: "center",
                  display: "flex",
                  color: "white",
                  fontSize: "30px",
                }}
              >
                {t("Contact")}
              </Language>
            </Col>
          </Row>
          <Row style={{ justifyContent: "space-evenly" }}>
            <Col lg={6} md={10} sm={12} xs={24}>
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  marginBottom: "25px",
                }}
              >
                <img src={"/img/telephone-call.png"} />
              </div>
              <Title>{t("Phone")}</Title>
              <Para>+40 790 218 260</Para>
            </Col>
            <Col lg={6} md={10} sm={12} xs={24}>
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  marginBottom: "25px",
                }}
              >
                <img src={"/img/whatsapp.png"} />
              </div>
              <Title>{t("WhatsApp")}</Title>
              <Para>+40 760 789 699</Para>
            </Col>
            <Col lg={6} md={10} sm={12} xs={24}>
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  marginBottom: "25px",
                }}
              >
                <img src={"/img/email.png"} />
              </div>
              <Title>{t("Email")}</Title>
              <Para>help@bobconcierge.ro</Para>
            </Col>
          </Row>
          <Row style={{ marginTop: "50px", justifyContent: "center" }}>
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo.svg"
                  aria-label="homepage"
                  width="151px"
                  height="94px"
                />
              </LogoContainer>
            </NavLink>
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <p style={{ color: "white", width: "100%", textAlign: "center" }}>
              {t("ProducedInRo")}
            </p>
          </Row>
          <Row style={{ marginTop: "20px", justifyContent: "center" }}>
            <p
              style={{
                color: "#517397",
                textAlign: "center",
                fontSize: "16px",
              }}
            >
              {t("Copyright")}
            </p>
          </Row>
        </Container>
      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer);
