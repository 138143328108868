import { StyledButton } from "./styles";
import { ButtonProps } from "../types";

export const Button = ({
  fixedWidth,
  children,
  onClick,
}: ButtonProps) => (
  <StyledButton color={'#a3c44b'} fixedWidth={fixedWidth} onClick={onClick}>
    {children}
  </StyledButton>
);
