import { Row } from "antd";
import { withTranslation } from "react-i18next";
import { Button } from "../../../common/Button";
import scrollToElement from "../../../common/utils/scrollToHelper";
import { ContentBlockProps } from "../types";
import { Fade } from "react-awesome-reveal";
import {
    TopBlockContainer,
    Content,
    ContentWrapper,
} from "./styles";

const TopBlock = ({
                        title,
                        content,
                        button,
                        t,
                        id,
                    }: ContentBlockProps) => {
    return (
        <TopBlockContainer>
            <Fade direction="right"  >
                <Row justify="space-between" align="middle" id={id}>
                    <ContentWrapper style={{ justifyContent: 'start'}}>
                        <h6 style={{color: 'black', fontSize: '42px',
                            width: '85%', fontWeight:'bold'}}
                        >{t(title)}
                        </h6>
                        <Content style={{color: 'black'}}>{t(content)}</Content>
                            {typeof button === "object" &&
                            button.map((item: any, id: number) => {
                                return (
                                    <Button
                                        key={id}
                                        fixedWidth={true}
                                        onClick={() => scrollToElement("contact")}
                                    >
                                        {t("ApplyNow")}
                                    </Button>
                                );
                            })}
                    </ContentWrapper>

                </Row>
            </Fade>
        </TopBlockContainer>
    );
};

export default withTranslation()(TopBlock);
